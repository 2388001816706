form {
  label {
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0.07em;
    text-align: left;
    text-transform: uppercase;

  }

  .form-control {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    border-radius: 10px;
    border: 1px solid #CDC5BC;
    padding: 14.5px 15px;
    height: auto;
  }

  .labelfocus {
    color: #000;
  }

  .col-form-label {
    font-size: 0.75rem;
    text-align: right;
  }

  .form-group {
    margin-bottom: 1rem;
  }

  .form-control {
    /*border: 1px solid #A6A9AE;*/
    color: #01173A;

    &::-webkit-input-placeholder {
      color: darken($body-bg, 20%);
    }

    &:-moz-placeholder { /* Firefox 18- */
      color: darken($body-bg, 20%);
    }

    &::-moz-placeholder { /* Firefox 19+ */
      color: darken($body-bg, 20%);
    }

    &:-ms-input-placeholder {
      color: darken($body-bg, 20%);
    }

    &:focus {
      border-color: #000;
    }
  }

  .form-control-position {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    display: block;
    width: 2.5rem;
    height: 2.5rem;
    line-height: 2.2rem;
    text-align: center;
  }

  .has-icon-left {
    .form-control-position {
      right: auto;
      left: inherit;
    }

    .form-control {
      padding-right: .85rem;
      padding-left: 2.95rem;
    }
  }

  .has-icon-right {
    .form-control {
      padding-right: 2.5rem;
    }
  }

  .form-section {
    color: theme-color('dark');
    line-height: 3rem;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
    font-weight: 400;
    margin-bottom: 20px;
    border-bottom: 1px solid theme-color('dark');

    i {
      font-size: 20px;
      margin-left: 5px;
      margin-right: 5px;
    }
  }

  /* .form-body{
      overflow: hidden;
  } */

  .form-actions {
    border-top: 1px solid darken($body-bg, 10%);
    padding: 20px 0;
    margin-top: 20px;

    &.filled {
      background-color: $body-bg;
    }

    &.center {
      text-align: center;
    }

    &.right {
      text-align: right;
    }

    &.top {
      border-top: 0;
      border-bottom: 1px solid darken($body-bg, 10%);
      margin-top: 0;
      margin-bottom: 20px;
    }

    @include media-breakpoint-down(sm) {
      .buttons-group {
        float: left !important;
        margin-bottom: 10px;
      }
    }
  }

  &.form-horizontal {
    .form-group {
      @include media-breakpoint-up(sm) {
        .label-control {
          text-align: right;
        }
      }
    }
  }

  &.row-separator {
    .form-group {
      margin: 0;
      border-bottom: 1px solid darken($body-bg, 3%);

      .label-control {
        padding-top: 1.5rem;
      }

      > div {
        padding: 1.2rem;
        padding-right: 0;
      }

      &.last {
        border-bottom: 0;
      }
    }
  }

  &.form-bordered {
    .form-group {
      margin: 0;
      border-bottom: 1px solid darken($body-bg, 3%);

      .label-control {
        padding-top: 1.5rem;
      }

      > div {
        padding: 1.2rem;
        padding-right: 0;
        border-left: 1px solid darken($body-bg, 3%);
      }

      &.last {
        border-bottom: 0;
      }
    }
  }

  &.striped-rows {
    .form-group {
      margin: 0;

      .label-control, .col-form-label {
        padding-top: 1.5rem;
      }

      > div {
        padding: 1.2rem;
        padding-right: 0;
      }

      &:nth-child(even) {
        background-color: $body-bg;
      }
    }
  }

  &.striped-labels {
    .form-group {
      margin: 0;

      .label-control {
        padding-top: 1.5rem;
      }

      > div {
        padding: 1.2rem;
        padding-right: 0;
        background-color: white;
      }

      &:nth-child(even) {
        background-color: $body-bg;
      }
    }
  }
}

select.form-control {
  /*padding-top: 0 !important;
  padding-bottom: 0 !important;*/
}


/* 
TODO:M: To check with latest bootstrap version and remove it. 
Bootstrap Enhance */
// Custom checkbox
.custom-control {
  display: block;

  &.inline {
    display: inline;
  }
}

.custom-checkbox input:disabled:checked ~ .custom-control-indicator {
  opacity: 0.5;
}

.custom-checkbox input:disabled ~ .custom-control-indicator {
  cursor: not-allowed;
}

.custom-control-input > input:focus ~ .custom-control-indicator {
  padding: 0.51rem;
}

.custom-control-indicator {
  width: 1.1rem;
  height: 1.075rem;
  line-height: 1.05rem;
  top: 0.085rem;

  + .custom-control-description {
    margin-left: 1rem;
  }
}

.form-control {
  &:disabled,
  &[readonly] {
    background-color: #ECEFF1;
  }
}

.input-group-addon {
  background-color: #ECEFF1;
}

.form-control-xl {
  padding: 0.8rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control-xl:not([size]):not([multiple]) {
  height: calc(3.25rem + 2px);
}

.input-group-addon input[type="radio"], .input-group-addon input[type="checkbox"] {
  margin-top: -4px;
  margin-left: -1rem;
}

.form-control-xl, .input-group-xl > .form-control, .input-group-xl > .input-group-addon, .input-group-xl > .input-group-btn > .btn {
  padding: 0.8rem 1rem;
}

.ng-touched.ng-invalid {
  border-color: theme-color("danger");
}

[dir="rtl"] {
  form {
    .form-control-position {
      left: 0;
      right: auto;
    }

    .has-icon-left {
      .form-control-position {
        left: auto;
        right: inherit;
      }

      .form-control {
        padding-left: .85rem;
        padding-right: 2.95rem;
      }
    }

    .has-icon-right {
      .form-control {
        padding-left: 2.5rem;
        padding-right: unset;
      }
    }


    .form-actions {
      &.right {
        text-align: left;
      }

      @include media-breakpoint-down(sm) {
        .buttons-group {
          float: right !important;
        }
      }
    }

    &.form-horizontal {
      .form-group {
        @include media-breakpoint-up(sm) {
          .label-control {
            text-align: left;
          }
        }
      }
    }

    &.row-separator {
      .form-group {
        > div {
          padding-left: 0;
          padding-right: unset;
        }
      }
    }

    &.form-bordered {
      .form-group {

        > div {
          padding-left: 0;
          padding-right: unset;
          border-right: 1px solid darken($body-bg, 3%);
        }
      }
    }

    &.striped-rows {
      .form-group {
        > div {
          padding-left: 0;
          padding-right: unset;
        }

      }
    }

    &.striped-labels {
      .form-group {
        > div {
          padding-left: 0;
          padding-right: unset;
        }
      }
    }
  }

  .custom-control-indicator {
    + .custom-control-description {
      margin-right: 1rem;
      margin-left: auto;
    }
  }


  .input-group-addon input[type="radio"], .input-group-addon input[type="checkbox"] {
    margin-right: -1rem;
    margin-left: auto;
  }

  .btn[class*="btn-primary"] {
    background-color: var(--dl-primary-button-bg);
    color: var(--dl-primary-button-color);
    height: auto;

  }
}

.react-toggle--checked .react-toggle-track {
  background-color: #5F69FF;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  /*background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23FF586B' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23FF586B' stroke='none'/%3e%3c/svg%3e");*/
  background-image: url("data:image/svg+xml,%3Csvg width='21' height='20' viewBox='0 0 21 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.4546 9.76942C17.8915 12.3366 16.0371 15.5027 13.0967 15.5027L10.8281 15.5027L8.55944 15.5027C5.61896 15.5027 3.76463 12.3366 5.20155 9.76941L7.47015 5.71632C8.93983 3.0906 12.7163 3.09059 14.1859 5.71631L16.4546 9.76942Z' fill='%23D11515' stroke='%23D11515' stroke-width='2.3'/%3E%3Cpath d='M12.2073 6.55399L11.6125 10.0289C11.5382 10.4223 11.1665 10.7501 10.646 10.7501C10.2 10.7501 9.7539 10.4223 9.67957 10.0289L9.08482 6.55399C8.93615 5.70165 9.67957 4.98047 10.646 4.98047C11.6125 4.98042 12.2816 5.70165 12.2073 6.55399Z' fill='white'/%3E%3Cpath d='M10.5453 13.3743C10.1107 13.3743 9.7585 13.0221 9.7585 12.5875C9.7585 12.153 10.1107 11.8008 10.5453 11.8008C10.9798 11.8008 11.332 12.153 11.332 12.5875C11.332 13.0221 10.9798 13.3743 10.5453 13.3743Z' fill='white'/%3E%3C/svg%3E%0A");

  &.typePassword {
    background-position: right calc(0.375em + 2.5rem) center;
  }
}
