.App {
    text-align: center;
}

/* landing */
.landing {
    width: 100% !important;
    max-width: none !important;
    position: relative;
    background: url('./assets/img/bg.jpg') no-repeat center center/cover;
    height: 100vh;
}

.smsVerification {
    width: 100% !important;
    max-width: none !important;
    position: relative;
    background: url('./assets/img/bg.jpg') no-repeat;
    height: 100vh;
}

.pull-left {
    text-align: left !important;
}

.layout-light .hover-color:hover {
    background: repeating-linear-gradient(-55deg, #f5f7fa, #f5f7fa 10px, #fff 10px, #fff 20px);
}

.hover-color:hover {
    background: repeating-linear-gradient(-55deg, #232, #228 10px, #333 10px, #333 20px);
}

input[type='file'] {
    opacity: 0;
}

.tableStyle {
    width: 120% !important;
    font-size: 0.9rem !important;
}

.table-style-transaction {
    width: 150% !important;
    text-size-adjust: inherit !important;
    font-size: 0.9rem !important;
}

.permission-btn {
    margin-left: 90% !important;
    background-color: #6be2a5 !important;
}

.table-text-center {
    text-align: center !important;
}

.font-11 {
    font-size: 11px !important;
    padding-left: 10px !important;
}

.model-header {
    background-color: lavender !important;
}

.menu-sidebar {
    padding-left: 10px !important;
}

.wrapper.layout-dark .permissions-card {
    padding: 20px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    background-color: #404258;
}

.permissions-inner {
    padding: 10px 12px;
}

.permissions-inner .form-check-input {
    margin-left: 0;
}

.permissions-inner .card-text {
    padding-left: 30px;
}

.notifications {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 20;

    padding: 20px;
    background-color: #404258;
    color: white;
}

.pagination-container {
    position: fixed;
    left: 275px;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
}

.pagination {
    list-style: none;
    display: flex;
    border: 1px solid #2e2f42;
}

.pagination-item {
    height: 40px;
    width: 40px;
    text-align: center;
    line-height: 40px;
    background-color: #3b4452;
    color: white;
    border-left: 1px solid #2e2f42;
    cursor: pointer;
}

.pagination-item:first-child {
    border-left: 0;
}

.wrapper.layout-dark.sidebar-md {
    min-height: 100vh;
}

.pagination-item:hover {
    background-color: #404258;
}

/* common styles */
.flex {
    display: flex;
}

.align-center {
    align-items: center;
}

.justify-center {
    justify-content: center;
}

.justify-end {
    justify-content: flex-end;
}

.mr-10 {
    margin-right: 10px;
}

.ml-10 {
    margin-left: 10px;
}

.dropdown-item.flex {
    display: flex;
}
