@mixin form-dark {
  .app-sidebar {
    background-color: var(--dl-bg-sidebar-color);
  }
  .input-group-text {
    background-color: var(--dl-bg-color);
    color: var(--dl-body-text-color);
  }
  form {
    label {
      color: var(--dl-body-text-color);
    }

    &.form-bordered .form-group {
      border-color: var(--dl-border-color);

      > div {
        border-color: var(--dl-border-color);
      }
    }

    &.striped-rows .form-group:nth-child(even) {
      background-color: var(--dl-body-bg);
    }
  }
  .form-control {
    background-color: var(--dl-bg-color);
    color: var(--dl-body-text-color);
  }
  .profile-sidebar {
    a {
      color: var(--dl-text-color);
    }

    border-top-color: var(--dl-border-color);
  }
  .main-dashboard {
    background-color: var(--dl-light-bg-color);
    color: var(--dl-body-text-color);
  }

  .main-global-panel {
    background-color: var(--dl-light-bg-color);
    color: var(--dl-body-text-color);
  }
  .global-footer {
    background-color: var(--dl-light-bg-color);
    color: var(--dl-body-text-color);
  }
  .global-main {
    background-color: var(--dl-light-bg-color);
    /*color: var(--dl-body-text-color);*/
  }

  .welcome-accordion {
    background-color: var(--dl-card-bg-color);
  }
}


@mixin card-dark {
  .card {
    background-color: var(--dl-bg-color);

    .card-title,
    .card-subtitle {
      /*color: var(--dl-body-headings-color);*/
    }

    .card-footer,
    .nav-item .nav-link,
    .nav-tabs-vc > ul.nav {
      /*background-color: var(--dl-bg-sidebar-color);*/
    }
  }
}

@mixin dropdown-dark {
  .dropdown-menu {
    background-color: var(--dl-bg-color);
    color: var(--dl-body-text-color);

    a {
      &:hover,
      &:focus {
        text-decoration-line: unset;
      }
    }

    .dropdown-item {
      color: var(--dl-body-text-color);

      a {
        color: var(--dl-body-text-color);

        &:hover,
        &:focus {
          text-decoration-line: unset;
        }
      }

      &:hover,
      &:focus {
        background-color: var(--dl-body-bg);
        color: var(--dl-body-headings-color);
      }
    }
  }
}


.wrapper,
.wrapper-popup {
  &.layout-dark {
    // Main
    background-color: var(--dl-body-bg);
    color: var(--dl-body-text-color);
    height: 100%;

    main {
      color: var(--dl-body-text-color);
    }


    .bg-white {
      background-color: var(--dl-bg-color) !important;
    }

    //Typo & tables
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6,
    .content-header,
    .table th {
      color: var(--dl-body-headings-color);
      font-family: var(--default-font-family);
    }

    .h1,
    .h2,
    .h3 {
      font-weight: 600;
    }

    p,
    .content-sub-header,
    .table td,
    .list-group-item-action,
    .nav-tabs .nav-link.active,
    .content-sub-header {
      color: var(--dl-body-text-color);
    }

    .page-item {
      &.active {
        .page-link {
          background-color: var(--dl-body-bg);
        }
      }

      .page-link {
        background-color: var(--dl-bg-color);
        color: var(--dl-body-text-color);
        border-color: var(--dl-border-color);
      }
    }

    .progress {
      background-color: var(--dl-body-bg);
    }

    .breadcrumb {
      background-color: var(--dl-body-bg);

      .active.breadcrumb-item {
        color: var(--dl-body-headings-color);
      }
    }

    // Charts & SVG
    // svg {
    //    stroke: var(--dl-body-headings-color) !important;
    // }
    .ct-grid {
      // stroke: #77798e;
      stroke: #ffffff38;
    }

    .ct-label {
      color: #77798e;
    }

    .table {
      thead th {
        border-bottom: 2px solid var(--dl-border-color);
      }

      th,
      td {
        border-top: 2px solid var(--dl-border-color);
      }
    }

    .text-muted {
      color: var(--dl-body-text-muted-color) !important;
    }

    // List group
    .list-group-item {
      background-color: inherit;
    }

    //dropdown
    /*.dropdown-menu {
       background-color: var(--dl-bg-color);
       color: var(--dl-body-text-color);
       .dropdown-item {
          color: var(--dl-body-text-color);

          a {
             color: var(--dl-body-text-color)
          }

          &:hover,
          &:focus {
             background-color: var(--dl-body-bg);
             color: var(--dl-body-headings-color);
          }
       }
    }*/
    @include dropdown-dark;

    //Navbar
    .navbar {
      .navbar-nav .nav-link {
        color: var(--dl-body-text-color);
      }

      .list-group-item {
        background-color: var(--dl-bg-color);
      }

      .scrollbar-container {
        &.bg-grey.bg-lighten-5 {
          background-color: var(--dl-bg-color) !important;
        }

        .border-bottom-grey.border-bottom-lighten-3 {
          border-color: var(--dl-border-color) !important;
        }
      }
    }

    @include form-dark;
    @include card-dark;

    //Email App
    .email-application {
      .email-app-sidebar,
      .email-app-list-mails,
      .email-app-mail-content {
        background-color: var(--dl-bg-color);
        border-right: 1px solid var(--dl-body-text-color);
        border-color: var(--dl-border-color);
      }

      .email-app-menu .form-group-compose,
      .email-app-list-mails .email-actions {
        border-bottom: 1px solid var(--dl-border-color);
      }

      .email-app-list-mails .email-actions {
        border-top: 1px solid var(--dl-border-color);
      }

      .list-group-item.active,
      .list-group-item.active:focus,
      .list-group-item.active:hover {
        background-color: #71b6f9;
        border-color: #71b6f9;
        color: $white;
      }

      .list-group-item-action {
        color: var(--dl-body-text-color);

        &.bg-grey.bg-lighten-4 {
          background-color: #4f5069 !important;
        }
      }
    }

    // Chat application
    .chat-application {
      .chat-app-window,
      .chat-sidebar,
      .chat-fixed-search {
        background-color: var(--dl-bg-color);
      }

      .chat-name,
      .chat-sidebar,
      .chat-fixed-search {
        border-color: var(--dl-border-color);
      }

      .bg-blue-grey.bg-lighten-5 {
        background-color: #4f5069 !important;
      }
    }

    // Contact application
    .contact-application {
      .contact-app-sidebar,
      .contact-app-list-mails,
      .contact-app-mail-content {
        background-color: var(--dl-bg-color);
        border-color: var(--dl-border-color);
      }

      .contact-app-menu .form-group-compose {
        border-bottom: 1px solid var(--dl-border-color);
      }

      .list-group-item.active,
      .list-group-item.active:focus,
      .list-group-item.active:hover {
        background-color: #71b6f9;
        border-color: #71b6f9;
        color: $white;
      }

      .list-group-item-action {
        color: var(--dl-body-text-color);

        &.bg-grey.bg-lighten-4 {
          background-color: #4f5069 !important;
        }
      }
    }

    // Todo application
    .todo-application {
      .todo-app-sidebar,
      .todo-app-list-mails,
      .todo-app-mail-content {
        background-color: var(--dl-bg-color);
        border-color: var(--dl-border-color);
      }

      .todo-app-menu .form-group-compose {
        border-bottom: 1px solid var(--dl-border-color);
      }

      .list-group-item.active,
      .list-group-item.active:focus,
      .list-group-item.active:hover {
        background-color: #71b6f9;
        border-color: #71b6f9;
        color: $white;
      }

      .list-group-item-action {
        color: var(--dl-body-text-color);

        &.bg-grey.bg-lighten-4 {
          background-color: #4f5069 !important;
        }
      }
    }

    //Timeline
    .horizontal-timeline-wrapper li[data-radium="true"] {
      background-image: none !important;
    }

    //Invoice template
    .invoice-template {
      .bg-grey.bg-lighten-4 {
        background-color: var(--dl-bg-color) !important;
      }
    }

    //User auth & error pages
    .login-layout {
      background-color: var(--dl-bg-color);
    }

    //Misc
    ol.progtrckr li.progtrckr-doing {
      color: var(--dl-body-headings-color);
    }

    .ReactTable .rt-thead.-filters input,
    .ReactTable .rt-thead.-filters select {
      background-color: var(--dl-body-bg);
    }

    .ReactTable .-pagination .-btn {
      color: var(--dl-body-headings-color);
    }

    .rbc-off-range-bg {
      background-color: var(--dl-bg-color);
    }

    .rbc-today {
      background-color: var(--dl-body-bg);
    }

    .rbc-toolbar button {
      color: var(--dl-body-text-color);
    }

    .customizer .customizer-content {
      background-color: var(--dl-body-bg);
    }

    .no-result {
      color: #bdbdc7;
      margin-left: auto;
      margin-right: auto;
    }

    .popup-header {
      /*color: #fff;*/
    }

    .chart-box {
      background-color: var(--dl-bg-color);
    }

    &.login-layout {
      .card {
        .wrapper-popup.layout-dark {

          &.card-body {
            background-color: var(--dl-modal-bg) !important;
          }


        }

        .card-footer {
          background-color: var(--dl-modal-bg) !important;
        }
      }
    }

    &.outside {
      background-color: #FFF9F2;
    }
  }

  //layout dark end

  .color-white {
    color: #fff;
  }

  .form-control::placeholder {
    color: #bdbdc7;
  }
}

.layout-dark {
  .modal-content {

    .modal-header {
      background-color: var(--dl-modal-header-bg);

      .close, .modal-title {
        color: #fff;
      }
    }

    .modal-body {
      background-color: #fff;
      color: var(--dl-body-text-color);
    }

    .modal-footer {
      background-color: #FCEEDE;
    }
  }


  .hover-color:hover {
    background: repeating-linear-gradient(
                    -55deg,
                    #232,
                    #228 10px,
                    #333 10px,
                    #333 20px
    );
  }

  .download-icon {
    color: #fff;
  }

  .light-color {
    color: #fff;
  }

  @include dropdown-dark;
  @include form-dark;
  @include card-dark;

  .navbar-main {
    background-color: transparent;


    .content-header {
      color: var(--dl-main-navbar-text);
    }
  }

}

.custom-modal {
  .modal-content, .modal-header {
    color: #fff;
    background-color: var(--dl-body-bg) !important;

    .close {
      color: #fff;
    }
  }
}

