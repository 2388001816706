
.selectpicker {
  margin-bottom: 1.5rem;
}

.custom-select {
  &__indicator-separator {
    display: none;
  }

  &__indicator.custom-select__dropdown-indicator {
    padding: 8px 0;
    color: #75787d;
    width: 17px;
  }
}

.wrapper.main-layout.layout-dark {
  .custom-select {
    &__control {
      background-color: #404258;
      border: 1px solid #71b6f9;
    }

    &__menu {
      background-color: #404258;
    }

    &__option {
      background-color: none;
      cursor: pointer;
      background-color: transparent !important;

      &:hover {
        background-color: #363f4c !important;
      }
    }

    &__single-value {
      color: #bdbdc7;
      font-weight: 400;
    }

    &:focus {
      outline: none;
    }

    &__input {
      color: #bdbdc7;
    }
  }
}

.wrapper.main-layout.layout-light {
  .custom-select {
    &__control {
      border: 1px solid #71b6f9;
    }

    &__single-value {
      font-weight: 400;
      color: #75787d;
    }

    &:focus {
      outline: none;
    }
  }
}
