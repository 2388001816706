a:hover {
  .btn {
    text-decoration: unset !important;
  }
}

.btn {
  &[class*="btn-"] {
    /*margin-bottom: 1rem;*/
    /*font-weight: 500;
    font-family: 'Roboto', 'Helvetica', sans-serif;*/
    font-family: var(--default-font-family);
    font-size: 16px;
    font-style: normal;
    font-weight: bold;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
    padding: 9px 22px;
    border-radius: 50px;
    height: auto;
    text-decoration: unset !important;

    span {
      text-decoration: unset !important;
    }

    svg {
      margin-right: 11px;
    }

    &.icon-left {
      svg {
        margin-right: 11px;
        margin-left: unset;
      }
    }

    &.icon-right {
      svg {
        margin-right: unset;
        margin-left: 11px;
      }
    }

    &.no-label {
      padding: 10px 22px;
      margin: 0;

      svg {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }

  &[disabled] {
    cursor: default;
  }

  &[class*="btn-primary"] {
    background-color: var(--dl-body-text-color);
    /*@include button-variant(#ffffff, #01173A, #01173A);*/

    border-color: var(--dl-body-text-color);
    color: #fff;
    /*box-shadow: 0px 2px 2px 1px rgba(1, 23, 58, 0.3);*/

    &:hover {
      background-color: #5F69FF;
    }

    &.disabled,
    &[disabled] {
      background-color: #9197a8;
      color: #afb4bf;

      &:hover {
        background-color: #9197a8;
        color: #afb4bf;
      }
    }
  }

  &[class*="btn-outside"] {
    background-color: var(--dl-primary-button-bg);
    @include button-variant(#01173A, #00D97C, #00D97C);
    box-shadow: 2px 4px 5px 1px rgba(1, 23, 58, 0.4);
  }

  &[class*="btn-light"] {
    //border-width: 2px solid #01173A;
    border-width: 2px;
    box-shadow: 0px 2px 2px 1px #01173A4D;

    @include button-variant(#01173A, #FFFFFF, #01173A);

    &:hover {
      border-color: #5F69FF;
    }

    &.disabled,
    &[disabled] {

      &:hover {
        border-color: #01173A;
      }
    }
  }

  &[class*="btn-raised"] {
    /*border-color: #71b6f9;*/
  }
}

.btn-toolbar {
  > .btn {
    margin-right: 15px;
  }
}

.btn-group-vertical {
  .btn {
    &[class*="btn-"] {
      margin-bottom: 0;
    }
  }
}

.input-group {
  .btn {
    &[class*="btn-"] {
      margin-bottom: 0;
    }
  }

  margin-bottom: 1rem;
}

// Custom round buttons
.btn-round {
  border-radius: $border-radius-extreme;
}

.btn-square {
  border-radius: 0;
}

.btn:active,
.btn.active {
  background-image: initial;
}

button:hover {
  cursor: pointer;
}

.btn-group > .btn.active {
  z-index: 0;
}

.btn-floating {
  position: fixed;
  bottom: 20px;
  right: 20px;
}

.btn-secondary {
  border-color: transparent;
}
