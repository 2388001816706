// Modals
// https://www.google.com/design/spec/components/dialogs.html#dialogs-specs

.modal-content {
  border-radius: 10px 10px 0 0;

  .modal-header {

    padding: 13px 20px;
    align-items: center;

    .modal-title {
      font-family: var(--default-font-family);
      font-size: 20px;
      font-style: normal;
      font-weight: 800;
      line-height: 20px;
      letter-spacing: 0px;
      text-align: left;

    }
  }

  .modal-footer {

    .btn {
      margin: 0;
      display: flex;
      align-items: center;
      padding: 10px 25px;

      &.icon-left {
        svg {
          margin-right: 8px;
        }
      }

      &.icon-right {
        svg {
          margin-left: 8px;
        }
      }
    }

    .btn + .btn {
      margin-left: 13px;
    }
  }

  .MuiFormControl-root {

    .MuiFormLabel-root {
      font-family: var(--default-font-family);
      font-size: 10px;
    }

    .MuiInputLabel-root {
      color: #677489;

      &.Mui-focused {
        color: #BDBDBD;
      }

      &.MuiInputLabel-filled.MuiInputLabel-shrink {
        transform: translate(12px, 7px) scale(1);
      }

      &.Mui-error {
        color: #D11515;
      }

      &.Mui-required {
        .MuiInputLabel-asterisk {
          //color: #D11515;
        }
      }

    }

    .MuiFilledInput-root {

      &.Mui-error {
        border-color: #D11515;
        /*padding-right: calc(1.5em + 0.75rem);
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23FF586B' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23FF586B' stroke='none'/%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-position: right calc(0.375em + 0.1875rem) center;
        background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);*/
      }

      &.Mui-disabled {
        background-color: #fff;
      }

      &.MuiSelect-select {
        &:focus {
          background-color: #fff;
        }
      }

      &.MuiFocused {
        .MuiSelect-filled {
          background-color: #fff;
        }
      }
    }

    .MuiFilledInput-input {
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 21px;
      letter-spacing: 0em;
      text-align: left;
      border-radius: 10px;
      /*border: 1px solid #CDC5BC;*/
      height: auto;
      padding: 17px 12px 16px 10px;

      &::placeholder {
        color: #bdbdc7;
      }
    }

    .MuiFormHelperText-root {
      &.Mui-error {
        color: #D11515;
        font-size: 10px;
        font-style: normal;
        font-weight: 700;
        line-height: 15px;
        letter-spacing: 0em;
        text-align: right;
      }
    }
  }

  button.close {
    opacity: 1;
  }

}
