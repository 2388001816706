// Theme Colors
$theme-colors: (
        primary: #71b6f9,
        success: #0CC27E,
        info: #1CBCD8,
        warning: #FF8D60,
        danger: #FF586B,
        secondary: #868e96,
        light: #f8f9fa,
        dark: #343a40
);

// Spacing

$spacer: 1.5rem;

$spacers: (
        0: 0,
        1: ($spacer * .25),
        2: ($spacer * .50),
        3: $spacer,
        4: ($spacer * 1.5),
        5: ($spacer * 3)
) !default;

// Body
$body-bg: #f5f7fa;

// Fonts
$font-family-sans-serif: var(--default-font-family);
$font-family-content: var(--default-font-family); //"Rubik", "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;

$font-size-root: 16px;

//Navbar
$navbar-padding-y: 0.75rem;
$navbar-padding-x: 0;
