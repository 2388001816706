@import 'partials/variables';
// colors, fonts etc...

@import 'partials/mixins';
// custom mixins

@import 'partials/layout'; // responsive grid and media queries

.cd-horizontal-timeline {
  opacity: 0;
  margin: 2em auto;
  -webkit-transition: opacity .2s; /* Safari */
  transition: opacity .2s;

  &::before {
    /* never visible - this is used in jQuery to check the current MQ */
    content: 'mobile';
    display: none;
  }

  &.loaded {
    /* show the timeline after events position has been set (using JavaScript) */
    opacity: 1;
  }

  .timeline {
    position: relative;
    height: 100px;
    width: 90%;
    max-width: 800px;
    margin: 0 auto;

    ul, ol {
      li {
        list-style: none;
      }
    }
  }

  .events-wrapper {
    position: relative;
    height: 100%;
    margin: 0 40px;
    overflow: hidden;

    &::after, &::before {
      /* these are used to create a shadow effect at the sides of the timeline */
      content: '';
      position: absolute;
      z-index: 2;
      top: 0;
      height: 100%;
      width: 20px;
    }

    &::before {
      left: 0;
    }

    &::after {
      right: 0;
    }
  }

  .events {
    /* this is the grey line/timeline */
    position: absolute;
    z-index: 1;
    left: 0;
    top: 49px;
    height: 2px;
    /* width will be set using JavaScript */
    background: darken($color-4, 10%);
    -webkit-transition: transform .4s; /* Safari */
    transition: transform .4s;
  }

  .filling-line {
    /* this is used to create the green line filling the timeline */
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: theme-color("primary");
    -ms-transform: scaleX(0); /* IE 9 */
    -webkit-transform: scaleX(0); /* Safari 3-8 */
    transform: scaleX(0);
    -ms-transform-origin: left center; /* IE 9 */
    -webkit-transform-origin: left center; /* Safari 3-8 */
    transform-origin: left center;
    -webkit-transition: transform .3s; /* Safari */
    transition: transform .3s;

  }

  .events a {
    position: absolute;
    bottom: 0;
    z-index: 2;
    text-align: center;
    padding-bottom: 15px;
    color: $color-1;
    /* fix bug on Safari - text flickering while timeline translates */
    -ms-transform: translateZ(0); /* IE 9 */
    -webkit-transform: translateZ(0); /* Safari 3-8 */
    transform: translateZ(0);

    &::after {
      /* this is used to create the event spot */
      content: '';
      position: absolute;
      @include center(x);
      bottom: -5px;
      height: 12px;
      width: 12px;
      border-radius: 50%;
      border: 2px solid #dfdede;
      background-color: white;
      -webkit-transition: background-color .3s, border-color .3s; /* Safari */
      transition: background-color .3s, border-color .3s;
    }

    .no-touch &:hover::after {
      background-color: theme-color("primary");
      border-color: theme-color("primary");
    }

    &.selected {
      pointer-events: none;

      &::after {
        background-color: theme-color("primary");
        border-color: theme-color("primary");
      }
    }

    &.older-event::after {
      border-color: theme-color("primary");
    }
  }

  @include MQ(L) {
    margin: 6em auto;

    &::before {
      /* never visible - this is used in jQuery to check the current MQ */
      content: 'desktop';
    }
  }
}

.cd-timeline-navigation a {
  /* these are the left/right arrows to navigate the timeline */
  position: absolute;
  z-index: 1;
  @include center(y);
  height: 34px;
  width: 34px;
  border-radius: 50%;
  border: 2px solid darken($color-4, 10%);
  /* replace text with an icon */
  overflow: hidden;
  color: transparent;
  text-indent: 100%;
  white-space: nowrap;
  -webkit-transition: border-color .3s; /* Safari */
  transition: border-color .3s;

  &::after {
    /* arrow icon */
    content: '';
    position: absolute;
    height: 16px;
    width: 16px;
    left: 25%;
    top: 25%;
    bottom: auto;
    right: auto;
    // background: url(../assets/img/svg/cd-arrow.svg) no-repeat 0 0;
  }

  &.prev {
    left: 0;
    -ms-transform: translateY(-50%) rotate(180deg); /* IE 9 */
    -webkit-transform: translateY(-50%) rotate(180deg); /* Safari 3-8 */
    transform: translateY(-50%) rotate(180deg);
  }

  &.next {
    right: 0;
  }

  .no-touch &:hover {
    border-color: theme-color("primary");
  }

  &.inactive {
    cursor: not-allowed;

    &::after {
      background-position: 0 -16px;
    }

    .no-touch &:hover {
      border-color: darken($color-4, 10%);
    }
  }
}

.cd-horizontal-timeline .events-content {
  position: relative;
  width: 100%;
  margin: 2em 0;
  overflow: hidden;
  -webkit-transition: height .4s; /* Safari */
  transition: height .4s;

  li {
    list-style: none;
    position: absolute;
    z-index: 1;
    width: 100%;
    left: 0;
    top: 0;
    padding: 0 5%;
    opacity: 0;

    &.selected {
      /* visible event content */
      position: relative;
      z-index: 2;
      opacity: 1;
      -ms-transform: translateX(0); /* IE 9 */
      -webkit-transform: translateX(0); /* Safari 3-8 */
      transform: translateX(0);
    }

    &.enter-right, &.leave-right {
      -webkit-animation-name: cd-enter-right; /* Safari 4.0 - 8.0 */
      animation-name: cd-enter-right;
    }

    &.enter-left, &.leave-left {
      -webkit-animation-name: cd-enter-left; /* Safari 4.0 - 8.0 */
      animation-name: cd-enter-left;
    }

    &.leave-right, &.leave-left {
      -webkit-animation-direction: reverse; /* Safari 4.0 - 8.0 */
      animation-direction: reverse;
    }
  }

  li > * {
    margin: 0 auto;
    max-width: 800px;
  }

  em {
    display: block;
    font-style: italic;
    margin: 10px auto;

    &::before {
      content: '- ';
    }
  }

  em, p {
    line-height: 1.6;
  }

}

@-webkit-keyframes cd-enter-right {
  0% {
    opacity: 0;
    -ms-transform: translateX(100%); /* IE 9 */
    -webkit-transform: translateX(100%); /* Safari 3-8 */
    transform: translateX(100%);
  }

  100% {
    opacity: 1;
    -ms-transform: translateX(0%); /* IE 9 */
    -webkit-transform: translateX(0%); /* Safari 3-8 */
    transform: translateX(0%);
  }
}

@keyframes cd-enter-right {
  0% {
    opacity: 0;
    -ms-transform: translateX(100%); /* IE 9 */
    -webkit-transform: translateX(100%); /* Safari 3-8 */
    transform: translateX(100%);
  }

  100% {
    opacity: 1;
    -ms-transform: translateX(0%); /* IE 9 */
    -webkit-transform: translateX(0%); /* Safari 3-8 */
    transform: translateX(0%);
  }
}

@-webkit-keyframes cd-enter-left {
  0% {
    opacity: 0;
    -ms-transform: translateX(-100%); /* IE 9 */
    -webkit-transform: translateX(-100%); /* Safari 3-8 */
    transform: translateX(-100%);
  }

  100% {
    opacity: 1;
    -ms-transform: translateX(0%); /* IE 9 */
    -webkit-transform: translateX(0%); /* Safari 3-8 */
    transform: translateX(0%);
  }
}

@keyframes cd-enter-left {
  0% {
    opacity: 0;
    -ms-transform: translateX(-100%); /* IE 9 */
    -webkit-transform: translateX(-100%); /* Safari 3-8 */
    transform: translateX(-100%);
  }

  100% {
    opacity: 1;
    -ms-transform: translateX(0%); /* IE 9 */
    -webkit-transform: translateX(0%); /* Safari 3-8 */
    transform: translateX(0%);
  }
}

[dir="rtl"] {
  .cd-horizontal-timeline {
    .events-wrapper {
      &::before {
        right: 0;
        left: auto;
      }

      &::after {
        left: 0;
        right: auto;
      }
    }

    .events {
      right: 0;
      left: auto;
    }

    .filling-line {
      right: 0;
      left: auto;
    }

  }

  .cd-timeline-navigation a {
    &::after {
      /* arrow icon */
      right: 25%;
      left: auto;
    }

    &.prev {
      right: 0;
      left: auto;
    }

    &.next {
      left: 0;
      right: auto;
    }
  }

  .cd-horizontal-timeline .events-content {
    li {
      right: 0;
      left: auto;
    }
  }

}
