.FullPageLayout {
  position: relative;

  .NowMoneyLogo {
    position: absolute;
    left: 40px;
    top: 30px;
    height: 69px;
    z-index: 1;
  }
}

.Outside {
  min-height: 100vh !important;
}


