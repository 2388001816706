.GlobalSpinner {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 99;

  .SpinnerElement {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.LocalSpinner {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: transparent;
  z-index: 99;

  .SpinnerElement {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
